(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/native/assets/javascripts/app-device-handler.js') >= 0) return;  svs.modules.push('/components/utils/native/assets/javascripts/app-device-handler.js');

var svs = svs || {};
svs.utils = svs.utils || {};

(function(svs, $) {
  'use strict';

  var logger = svs.core.log.getLogger('component:core:native');
  var sessionDataKey = 'appDeviceData';
  var updatingDeviceDoneStatus = false;

  svs.utils.nativeAppDeviceHandler = (function() {
    var self;
    function updatingDeviceDone() {
      return updatingDeviceDoneStatus;
    }
    self = new svs.core.Events({
      updatingDeviceDone: updatingDeviceDone
    });
    return self;
  })();

  svs.core.deviceSession.get(sessionDataKey, 'native', function(result, err) {
    function done() {
      updatingDeviceDoneStatus = true;
      svs.utils.nativeAppDeviceHandler.trigger('updatingNotificationDevicesDone');
    }
    function updateDevice() {
      logger.debug('Requesting storage of device.');
      $.get({
        url: svs.core.urlMapping.get('registerNativeApp')
      })
        .done(function() {
          logger.debug('Storage of device done successfully');
        })
        .fail(function(xhr, textStatus, err) {
          logger.error('Request for storage of device failed', err);
        })
        .always(done);
    }
    if (result) {
      logger.debug('Found new device in session storage');
      if (svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
        updateDevice();
      }
    } else {
      if (err) {
        logger.error('Could not fetch native device data from session storage');
      } else {
        logger.debug('No native app data to persist');
      }
      done();
    }
  });
})(svs, jQuery);


 })(window);